import app_name from "../../assets/images/app_name.svg";
import app_logo from "../../assets/images/app_logo.svg";
import lang_icon from "../../assets/images/lang_icon.svg";
import home_image from "../../assets/images/home_image.svg";
import home_global from "../../assets/images/home_global.svg";
import showcard from "../../assets/images/showcard.svg";
import amazontv from "../../assets/images/amazontv.svg";
import tataev from "../../assets/images/tataev.svg";
import imdb from "../../assets/images/imdb.svg";
import langistan from "../../assets/images/langistan.svg";
import primevideo from "../../assets/images/primevideo.svg";
import videoproduction from "../../assets/images/videoproduction.svg";
import designservices from "../../assets/images/designservices.svg";
import postproduction from "../../assets/images/postproduction.svg";
import videoediting from "../../assets/images/videoediting.svg";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import linkedin from "../../assets/images/linkedin.svg";
import tiktok from "../../assets/images/tiktok.svg";
import xIcon from "../../assets/images/x.svg";
import youtube from "../../assets/images/youtube.svg";
import servicesplatformbcg from "../../assets/images/services_platform_bcg.svg";
import viewerbcg from "../../assets/images/viewerbcg.svg";
import viewer from "../../assets/images/viewer.svg";
import subscribercount from "../../assets/images/subscribercount.svg";
import videoproduced from "../../assets/images/videoproduced.svg";
import storyimage from "../../assets/images/storyimage.svg";
import primevideoicon from "../../assets/images/primeviewicon.svg";
import minitvicon from "../../assets/images/minitvicon.svg";
import tataevicon from "../../assets/images/tataevicon.svg";
import commenticon from "../../assets/images/commenticon.svg";
import tvshow from "../../assets/images/tvshow.svg";
import creativehouse from "../../assets/images/creativehouse.svg";
import creativehousedesign from "../../assets/images/creativehousedesign.svg";
import latestshort from "../../assets/images/latestshort.svg";
import lastestshortcat from "../../assets/images/lastestshortcat.svg";
import designwork from "../../assets/images/designwork.svg";
import designworkgraphics from "../../assets/images/designworkgraphics.svg";
import performancectr from "../../assets/images/performancectr.svg";
import performancesubscriber from "../../assets/images/performancesubscriber.svg";
import performancevideoes from "../../assets/images/performancevideoes.svg";
import performanceviewes from "../../assets/images/performanceviewes.svg";
import socialwork from "../../assets/images/socialwork.svg";
import facebookwhite from "../../assets/images/facebookwhite.svg";
import instagramwhite from "../../assets/images/instagramwhite.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import youtubewhite from "../../assets/images/youtubewhite.svg";
import appwhitelogo from "../../assets/images/appwhitelogo.svg";
import expert from "../../assets/images/expert.svg";
import owner from "../../assets/images/owner.svg";
import wehiring from "../../assets/images/wehiring.svg";
import yourbusiness from "../../assets/images/yourbusiness.svg";

const AppImages = {
  expert,
  owner,
  wehiring,
  yourbusiness,
  appwhitelogo,
  youtubewhite,
  facebookwhite,
  instagramwhite,
  whatsapp,
  socialwork,
  performancectr,
  performancesubscriber,
  performancevideoes,
  performanceviewes,
  designwork,
  designworkgraphics,
  lastestshortcat,
  latestshort,
  creativehouse,
  creativehousedesign,
  tvshow,
  commenticon,
  primevideoicon,
  minitvicon,
  tataevicon,
  viewer,
  subscribercount,
  videoproduced,
  facebook,
  instagram,
  linkedin,
  tiktok,
  xIcon,
  youtube,
  app_name,
  app_logo,
  lang_icon,
  home_image,
  home_global,
  showcard,
  amazontv,
  tataev,
  imdb,
  langistan,
  primevideo,
  videoproduction,
  designservices,
  postproduction,
  videoediting,
  servicesplatformbcg,
  viewerbcg,
  storyimage,
};

export default AppImages;
